// project imports
import config from "config";

// action - state management
import * as actionTypes from "./actions";

export const initialState = {
  isOpen: [], // for active default menu
  defaultId: "dashboard",
  fontFamily: config.fontFamily,
  borderRadius: config.borderRadius,
  opened: true,
  themeColor: config.themeColor,
  userAccountDetailsList: config.userAccountDetailsList,
  profileInfo: {}
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
  let id;
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_FONT_FAMILY:
      return {
        ...state,
        fontFamily: action.fontFamily,
      };
    case actionTypes.SET_THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor,
      };
    case actionTypes.SET_BORDER_RADIUS:
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    case actionTypes.SET_USER_ACCOUNT_LIST:
      return {
        ...state,
        userAccountDetailsList: action.userAccountDetailsList,
      };
      case actionTypes.SET_REDIRECT_ALLOW_ENABLED:
      return {
        ...state,
        isRedirectAlertEnabled: action.isRedirectAlertEnabled,
      };
      case actionTypes.SET_USER_SESSION_DETAILS:
      return {
        ...state,
        profileInfo: action.profileInfo,
      };
    case actionTypes.SET_COUNTRIES_LIST:
      return {
        ...state,
        countriesList: action.countriesList,
      };
    case actionTypes.SET_USER_ACCOUNT_DROPDOWNL_LIST:
      return {
        ...state,
        userAccountDropdownList: action.userAccountDropdownList,
      };
    case actionTypes.SET_SELECTION_MODE:
      return {
        ...state,
        selectedMode: action.selectedMode,
      }
    default:
      return state;
  }
};

export default customizationReducer;

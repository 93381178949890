import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import BillingPortal from 'pages/BillingPortal';
import Success from 'pages/RedirectingPages/Success';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('pages/Dashboard')));
const Portfolio = Loadable(lazy(() => import('pages/Portfolio')))
const OptionsActivities = Loadable(lazy(() => import('pages/OptionsActivities')))
const SubscriptionPlan = Loadable(lazy(() => import('pages/Subscription')))
const CommunityInsights = Loadable(lazy(() => import('pages/CommunityInsights')))
const Invoices = Loadable(lazy(() => import('pages/Invoice')))
const Profile = Loadable(lazy(() => import('pages/Profile')))
const ManagePortfolio = Loadable(lazy(() => import('pages/ManagePortfolio')))
const BalanceHistory = Loadable(lazy(() => import('pages/BalanceHistory')))
const AddLinkNewAccount = Loadable(lazy(() => import('pages/NewAccount')))
const ManagePortfolioNew = Loadable(lazy(() => import('pages/ManagePortfolioNew')))
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'options-activities',
      element: <OptionsActivities />
    },
    {
      path: 'portfolio',
      element: <Portfolio />
    },
    {
      path: 'manage-portfolio',
      element: <ManagePortfolio />
    },
    {
      path: 'billing',
      element: <BillingPortal />
    },
    // {
    //   path: 'subscriptions',
    //   element: <SubscriptionPlan />
    // },
    {
      path: 'community-insights',
      element: <CommunityInsights />
    },
    // {
    //   path: 'invoices',
    //   element: <Invoices />
    // },
    {
      path: '/billing/success',
      element: <Success />
    },
    {
      path: '/profile',
      element: <Profile />
    },
    {
      path: 'manage-portfolio',
      element: <ManagePortfolio />
    },
    {
      path: '/admin-portfolio',
      element: <Portfolio superAdmin={false} />
    },
    {
      path: '/admin-options-activities',
      element: <OptionsActivities superAdmin={false} />
    },
    {
      path: '/admin-invoices',
      element: <Invoices superAdmin={false} />
    },
    {
      path: '/balance-history',
      element: <BalanceHistory superAdmin={false} />
    },
    {
      path: '/support-admin-invoices',
      element: <Invoices superAdmin={false} />
    },
    {
      path: '/add-link-account',
      element: <AddLinkNewAccount superAdmin={false} />
    },
    {
      path: '/add-link-account/:brokerageParam',
      element: <AddLinkNewAccount superAdmin={false} />
    },
    {
      path: '/manage-portfolio-new',
      element: <ManagePortfolioNew superAdmin={false} />
    },
    
    
    // {
    //   path: 'utils',
    //   children: [
    //     {
    //       path: 'util-color',
    //       element: <UtilsColor />
    //     }
    //   ]
    // },
  ]
};

export default MainRoutes;

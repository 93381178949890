// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_THEME_COLOR = '@customization/SET_THEME_COLOR';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_USER_ACCOUNT_LIST = '@customization/SET_USER_ACCOUNT_LIST';
export const SET_REDIRECT_ALLOW_ENABLED = '@customization/SET_REDIRECT_ALLOW_ENABLED';
export const SET_USER_SESSION_DETAILS = '@customization/SET_USER_SESSION_DETAILS';
export const SET_COUNTRIES_LIST = '@customization/SET_COUNTRIES_LIST';
export const SET_USER_ACCOUNT_DROPDOWNL_LIST = '@customization/SET_USER_ACCOUNT_DROPDOWNL_LIST';
export const SET_SELECTION_MODE = '@customization/SET_SELECTION_MODE';

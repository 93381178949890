// assets
import { IconLayoutDashboard, IconLayoutKanban, IconServer2, IconSubtask, IconSettings, IconTimeline } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const mainMenu = {
  id: 'dashboard',
  title: 'Main Menu',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: IconLayoutDashboard,
      breadcrumbs: false
    },
    {
      id: 'portfolio',
      title: 'Portfolio',
      type: 'item',
      url: '/portfolio',
      icon: IconLayoutKanban,
      breadcrumbs: true
    },
    {
      id: 'options-activities',
      title: 'Options Activities',
      type: 'item',
      url: '/options-activities',
      icon: IconServer2,
      breadcrumbs: true
    },
    // {
    //   id: 'manage-portfolio',
    //   title: 'Manage Portfolio',
    //   type: 'item',
    //   url: '/manage-portfolio',
    //   icon: IconSettings,
    //   breadcrumbs: true
    // },
    {
      id: 'manage-portfolio-new',
      title: 'Manage Portfolio',
      type: 'item',
      url: '/manage-portfolio-new',
      icon: IconSettings,
      breadcrumbs: true
    },
    {
      id: 'balance-history',
      title: 'Balance History',
      type: 'item',
      url: '/balance-history',
      icon: IconTimeline,
      breadcrumbs: true
    }
    
  ]
};

export default mainMenu;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
function ActivationModalPopup(props) {
    const theme = useTheme();
  return (
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>
            <Typography variant="h2"
            sx={{
                color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading
            }}>{props.title}
            </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="h3" sx={{
                            color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
                        }}>
            {props.message}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button sx={{ ...theme.buttons.primaryButton, width: 'max-content' }} onClick={props.handleClose} color="primary">
            OK
            </Button>
        </DialogActions>
      </Dialog>
  );
}

export default ActivationModalPopup;
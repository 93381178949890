// assets
import { IconBrowserPlus, IconDeviceLaptop } from '@tabler/icons';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const researchInsights = {
  id: 'pages',
  title: 'Research & Insights',
  type: 'group',
  children: [
    {
      id: 'community-insights',
      title: 'Community Insights',
      type: 'item',
      url: '/community-insights',
      icon: IconBrowserPlus,
      breadcrumbs: true
    },
    {
      id: 'stockScreener',
      title: 'Stock Screener [coming soon]',
      type: 'item',
      url: '',
      icon: IconDeviceLaptop,
      breadcrumbs: true,
      disabled: true
    },
  ]
};

export default researchInsights;

// assets
import { IconLayoutKanban, IconServer2, IconFileInvoice } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const superAdminMenu = {
  id: 'super_admin',
  title: 'Super Admin',
  type: 'group',
  children: [
    {
      id: 'admin-portfolio',
      title: 'Portfolio',
      type: 'item',
      url: '/admin-portfolio',
      icon: IconLayoutKanban,
      breadcrumbs: true
    },
    {
      id: 'admin-invoices',
      title: 'Invoices',
      type: 'item',
      url: '/admin-invoices',
      icon: IconFileInvoice,
      breadcrumbs: true
    },
    {
      id: 'admin-options-activities',
      title: 'Options Activities',
      type: 'item',
      url: '/admin-options-activities',
      icon: IconServer2,
      breadcrumbs: true
    },
    {
      id: 'admin-balance-history',
      title: 'Balance History',
      type: 'item',
      url: '/admin-balance-history',
      icon: IconFileInvoice,
      breadcrumbs: true
    },
  ]
};

export default superAdminMenu;

const appName = "Quantum Trades";
const switchButtonlabel = "Show Other trades (Including Rejected)";
const statusDropDownPlaceHolder = "Status";
const tickerDropDownPlaceHolder = "Ticker";
const dateRangePlaceholder = "Select Date Range";
const accountDropDownDefaultValue = "All Account";
const accountDropDownPlaceHolder = "Account";
const footerText = {
  rights: "All Rights Reserved",
  privacy: "Privacy Policy",
  terms: "Terms of Use",
};
const pageTitle = {
  portfolio: "Portfolio",
  optionsActivity: "Options Activities",
  communityInsights: "Community Insights",
  invoice: "Invoices",
  balanceHistory: "Balance History",
  newAccount: "Add/Link New Account",
};

const newAccountAlert = {
  title: "Account Added",
  message: "Your account(s) have been added successfully",
};

const notes = {
  communityInsights:
    "Note: Community insights are generated starting with data from Aug-01-2023.",
};

const description = {
  dashboard: "The calculation of realized and unrealized gains is based on the execution of options contracts, including both buying and selling, according to specific strategies and selected tickers. It's important to note that this calculation does not include any additional income from interests or trades that are not conducted through our platform.",
  managePortfolio:
    "QuantumTrades uses your ticker selections and risk profile settings to generate options trades based on the wheel strategy. Select the tickers/stocks you want to hold long-term and your risk profile settings. The buy risk profile setting determines the price below the current market price at which you want to buy the stock through cash-secured puts. The sell risk profile setting determines the price above the current market price at which you are willing to sell the stock through covered calls",
  communityInsights: (
    <>
      Insights based on other QuantumTrades community members ticker selections
      and premiums received, considering risk profile settings,
      <br />
      <br /> This page can help traders make informed decisions about their own
      investments by providing insights into what other traders are doing.
      However, it is important to note that past performance is not indicative
      of future results and that traders should always do their own research
      before making any investment decisions.
      <br />
      {/* <div>
        <table>
          <thead>
            <tr>
              <td>item</td>
              <td>description</td>
            </tr>
          </thead>
          <tbody>
            <tr></tr>
          </tbody>
        </table>
      </div> */}
    </>
  ),
  portfolio:
    "Displays current brokerage account holdings, 15-minute delayed can be expected.",
  optionsActivity:
    "Displays all options trades executed for a given account, Select date range for more options activity ",
  invoice: "",
  subscription: "",
};
const privacy_policy_link = "https://quantumtrades.com/privacy-policy/";
//const terms_link = "https://quantumtrades.com/terms-of-service/";
const terms_link = "https://quantumtrades.com/terms-and-conditions/";

const strategyDescription = {
  strategy_put_spread:'VERTICAL PUT SPREADS [CREDIT] - WEEKLY',
  strategy_call_spread:'VERTICAL CALL SPREADS [DEBIT] - WEEKLY',
  strategy_wheel:'CASH SECURED - COVERED CALLS [WHEEL] [DEBIT] - WEEKLY',
  strangle:'',
  straddle:'',
  iron_condore:'',
  custom_strategy:''
}
export {
  appName,
  switchButtonlabel,
  pageTitle,
  statusDropDownPlaceHolder,
  tickerDropDownPlaceHolder,
  dateRangePlaceholder,
  accountDropDownDefaultValue,
  accountDropDownPlaceHolder,
  footerText,
  notes,
  description,
  privacy_policy_link,
  terms_link,
  newAccountAlert,
  strategyDescription
};

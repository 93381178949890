import React, { useState } from "react";
// material-ui
import {
  Snackbar,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  styled,
  Alert,
  Stack,
  Chip,
  Link,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { SET_USER_ACCOUNT_LIST } from "store/actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckIcon from "@mui/icons-material/Check";
// project imports
import Loader from "ui-component/Loader";
import * as SubscriptionConstants from "constants/Subscription";
import { updateSubscription, updateAccountSubcription,getInvoiceSubscription, getClientPortalURL } from "commonApi";
import IconList from "./List";
import MainCard from "ui-component/cards/MainCard";
import { CurrentPlanBG } from "./CurrentPlanBG";
import EmptyCard from "ui-component/cards/EmptyCard";
import * as Constants from "constants/Common";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Switch from "ui-component/Switch";
import ConfirmationDialog from "../../ui-component/ConfirmationDialog";
import ActivationModalPopup from "./ActivationModalPopup";
import dayjs from "dayjs";
// import startOfMonth from "date-fns/startOfMonth";
// import addMonths from "date-fns/addMonths";
import { startOfMonth, addMonths, format } from "date-fns";
import { useEffect } from "react";
const formattedDate = new Date();
const CardWrapper = styled(Card)(({ theme, cardColor }) => ({
  backgroundColor:
    cardColor || (theme?.customization?.themeColor === "dark" && "#313842"),
  borderRadius: "6px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  paddingBottom: "60px",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  height: "100%",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const SubscribeButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  bottom: "6px",
  position: "absolute",
  width: "77%",
  height: '40px',
  gap: '10px',
  ':hover, :focus': {
    textDecoration: 'none',
  }
}));

const SubscriptionPlan = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isAlertMSG, setIsAlertMSG] = useState(null);
  const [subPackage, setSubPackage] = useState("monthly");
  const [action, setAction] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [open, setOpen] = useState(false);
  const [openActivationModal, setOpenActivationModal] = useState(false);
  const [redirectURL, setRedirectURL] = useState()
  const accountDetails = useSelector(
    (state) => state.customization.userAccountDetailsList
  );
  const accountDropdownList = useSelector(
    (state) => state.customization.userAccountDetailsList.accountList
  );
  const profileInfo = useSelector((state) => state.customization.profileInfo);

  const handleSubmit = async (planName, isActivation, action) => {
    setIsLoading(true);
    let formValues = {
      new_subscription_plan: planName,
      brokerage_name: accountDetails.selectedAccount.brokerage_name,
      account_alias_number: accountDetails.selectedAccount.account_alias_number,
      brokerage_account_type:
        accountDetails.selectedAccount.brokerage_account_type,
    };
    if(accountDetails.selectedAccount.subscription_plan_name === null || accountDetails.selectedAccount.subscription_plan_name === ""){
      formValues={...formValues, date_of_joining:format(dayjs(new Date()).$d, "yyyy-MM-dd")}
    }
    // return false;
    const data = await updateSubscription(
      accountDetails.selectedAccount.account_id,
      formValues
    );
    if (data) {
      await getInvoiceSubscription(accountDetails.selectedAccount.account_id)
      dispatch({
        type: SET_USER_ACCOUNT_LIST,
        userAccountDetailsList: {
          accountList: accountDropdownList,
          selectedAccount: data,
        },
      });
      if(!isActivation){
        setIsAlert(true);
      }
      
      // setIsAlertMSG(SubscriptionConstants.successActivationMessage);
      setIsAlertMSG(
        action == "downgrade"
          ? SubscriptionConstants.successDowngradeMessage
          : action == "upgrade"
          ? SubscriptionConstants.successUpgradeMessage
          : ""
      );
      setIsLoading(false);
      if (isActivation && isActivation) {
        await updateAccountSubcription(
          accountDetails.selectedAccount.account_id
        );
        handleActivationModalClickOpen()
        // setIsAlertMSG(SubscriptionConstants.successMessage);
      }
    }
  };

  const getStripeURL = async () =>{
    setIsLoading(true)
    await getClientPortalURL(accountDetails?.selectedAccount?.account_id ?? 0)
      .then(res => {
        setIsLoading(false)
        setRedirectURL(res.url)
    }).catch(err => {
      setIsLoading(false)
      console.log(err)
    })
  }

  useEffect(()=>{
    if((accountDetails?.selectedAccount?.payment_status ?? '').toLowerCase() === 'paid'){
      const currPlanName = accountDetails?.selectedAccount?.subscription_plan_name.toLowerCase()
      setSubPackage(currPlanName.includes('yearly') ? 'yearly' : 'monthly')
      getStripeURL()
    }
  },[accountDetails?.selectedAccount.account_id])

  const getButtonBasedOnCurrentPlan = (index, planName) => {
    const currPlanName = accountDetails?.selectedAccount?.subscription_plan_name
    const filterIndex = currPlanName?.includes(planName.toLocaleUpperCase()) && currPlanName?.includes(subPackage.toLocaleUpperCase()) ? index : -1;
    // console.log(accountDetails?.selectedAccount?.subscription_plan_name, planName, filterIndex)
    if (
      accountDetails.selectedAccount &&
      (accountDetails.selectedAccount.subscription_plan_name === "" ||
        accountDetails.selectedAccount.subscription_plan_name === null ||
        accountDetails.selectedAccount.subscription_plan_name === undefined)
    ) {
      if (index === SubscriptionConstants.subscriptionPlans.length - 1){
        return planName === "Institution" && (
          <SubscribeButton
            sx={{ ...theme.buttons.subscriptionOutlinedButton }}
            href={SubscriptionConstants.contactUSLink}
            target="_blank"
          >
            Contact US
          </SubscribeButton>
        )
      }
      return (
        <SubscribeButton
          href={redirectURL}
          sx={{ ...theme.buttons.subscriptionOutlinedButton }}
          disabled={isLoading}
        >
          Activate (${SubscriptionConstants.subscriptionPlans[index].monthlyAmt / 100})
        </SubscribeButton>
      );
      
    } else {
      if (index < filterIndex) {
        return (
          <SubscribeButton
            href={redirectURL}
            sx={{ ...theme.buttons.subscriptionOutlinedButton }}
            disabled={isLoading}
            target="_blank"
          >
            Downgrade
          </SubscribeButton>
        );
      } else if (index > filterIndex) {
        return planName === "Institution" ? (
          <SubscribeButton
            sx={{ ...theme.buttons.subscriptionOutlinedButton }}
            href={SubscriptionConstants.contactUSLink}
            target="_blank"
          >
            Contact US
          </SubscribeButton>
        ) : (
          <SubscribeButton
            href={redirectURL}
            sx={{
              ...theme.buttons.subscriptionUpgradeButton,
              background: theme.palette.subscriptionBackground[planName],
              borderColor: "unset",
            }}
            disabled={isLoading}
            target="_blank"
          >
            Select Plan {isLoading && <CircularProgress color="inherit" size={18}/>}
          </SubscribeButton>
        );
      } else {
        return (
          <SubscribeButton
            sx={{ ...theme.buttons.subscriptionOutlinedButton }}
            disabled
            style={{
              padding: "12px",
              borderColor: "#65bf67",
              color: "#65bf67",
            }}
          >
            <CheckCircleIcon fontSize="small" color="success" />
            &nbsp; Current Plan
          </SubscribeButton>
        );
      }
    }
  };
  const PackageSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handelPackageChange = (event) => {
    setSubPackage(event.target.checked ? "yearly" : "monthly");
  };
  const handleEntering = () => {
    // if (radioGroupRef.current != null) {
    //   radioGroupRef.current.focus();
    // }
  };
  const handleCancel = () => {
    setOpen(false);
    setSelectedPlan("");
    setAction("");
    // onClose();
  };

  const handleOk = () => {
    handleSubmit(selectedPlan, false, action);
    setOpen(false);
    setSelectedPlan("");
    setAction("");
  };

  const handleActivationModalClickOpen = () => {
    setOpenActivationModal(true);
  };

  const handleActivationModalClose = () => {
    setOpenActivationModal(false);
  };

  return (
    <MainCard
      title="Subscription"
      secondary={
        accountDetails.selectedAccount ? (<Stack alignItems={'right'} sx={{float: 'right'}}  >
          <Stack variant="div">
            <Typography variant="h3" textAlign={'right'} sx={{ color: "#00BEFA" }}>
              {SubscriptionConstants.discountValue}% discount
            </Typography>
          </Stack>

          <Grid component="label" container alignItems="center" spacing={1} sx={{ justifyContent:'flex-end'}}>
            <Grid
              item
              sx={{
                color:
                  theme?.customization?.themeColor === "dark"
                    ? "#fff"
                    : theme.heading,
              }}
            >
              Monthly
            </Grid>
            <Grid item>
              <Switch
                sx={{ m: 1 }}
                onChange={handelPackageChange}
                className="showDisabled"
                checked={subPackage === 'yearly'}
                defaultChecked={false}
              />
            </Grid>
            <Grid
              item
              sx={{
                color:
                  theme?.customization?.themeColor === "dark"
                    ? "#fff"
                    : theme.heading,
              }}
            >
              Annual
            </Grid>
          </Grid>
          <Stack alignItems={'right'} sx={{float: 'right'}}  >
            <Typography variant="h3" textAlign={'right'} sx={{ color: "#00BEFA" }}> Current Plan : {accountDetails?.selectedAccount?.subscription_plan_name}</Typography> </Stack>
        </Stack>): (<></>)
      }
      sx={{ background: "none", border: "unset" }}
      pageDescription={Constants.description.subscription}
    >
      {isLoading && <Loader />}
      <Snackbar
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={isAlert}
        onClose={() => {
          setIsAlert(false);
        }}
      >
        <Alert
          onClose={() => {
            setIsAlert(false);
          }}
          severity="success"
          sx={{ width: "100%" }}
        >
          {isAlertMSG}
        </Alert>
      </Snackbar>
      {accountDetails ? (
        <Grid
          container
          columnSpacing={2}
          rowGap={4}
          sx={{ px: 5, position: "relative", mt: 2 }}
        >
          {SubscriptionConstants.subscriptionPlans.map((plan, index) => (
            <Grid
              item
              xxs={12}
              xs={6}
              sm={4}
              md={4}
              lg={3}
              xl={3}
              key={index}
              sx={{ display: "block", position: "relative", height: "auto" }}
            >
              <Stack sx={{ display: "contents" }}>
                <div
                  style={{
                    borderTopRightRadius: "6px",
                    borderTopLeftRadius: "6px",
                    height: "15px",
                    background:
                      theme.palette.subscriptionBackground[plan.title],
                  }}
                ></div>
                <Stack
                  sx={{
                    display: "grid",
                    textAlign: "right",
                    justifyItems: "right",
                    position: "absolute",
                    width: "-webkit-fill-available",
                    marginRight: "12px",
                    marginTop: "-22px",
                  }}
                >
                  {(accountDetails &&
                    Object.keys(accountDetails).length > 0 &&
                    accountDetails.selectedAccount &&
                    accountDetails.selectedAccount.subscription_plan_name) ===
                    plan.title && CurrentPlanBG}
                </Stack>
                <CardWrapper>
                  <CardContent>
                    <Title
                      sx={{
                        textAlign: "center",
                        color:
                          theme?.customization?.themeColor === "dark" && "#fff",
                      }}
                    >
                      {plan.title}
                    </Title>
                    <Stack sx={{ my: 2 }} alignItems={"center"}>
                      <img src={plan.logo} width={"66px"} height={"66px"} />
                    </Stack>
                  </CardContent>
                  <Stack
                    sx={{
                      width: "100%",
                      flexShrink: 0,
                      background:
                        theme.palette.subscriptionBackground[plan.title],
                    }}
                  >
                    {plan.title !== "Institution" ? (
                      <Grid container sx={{ py: 1, px: 2 }}>
                        {subPackage === "monthly" ? (

                          <Grid item lg={12} md={12} sm={12} xs={12} xxs={12}>
                            <Typography
                              variant="subscriptionPeriodlabel"
                              sx={{ float: "left" }}
                            >
                              Monthly
                            </Typography>

                            <Grid container sx={{ mb: 1 }}>
                              <Grid item lg={6} md={6} xs={6}>
                                <Typography
                                  variant="subscriptionTotalAmount"
                                  sx={{ float: "left" }}
                                >
                                  <p>{plan.currencySymbol}</p>
                                  {plan.monthlyAmt}
                                </Typography>
                                <Typography
                                  variant="subscriptionDiscountPercentage"
                                  sx={{ width: "min-content" }}
                                >
                                  {plan.discountValue}
                                </Typography>
                              </Grid>
                              <Grid item lg={6} md={6} xs={6}>
                                {plan.chipForMonthly && (
                                  <Chip
                                    label={plan.chipForMonthly}
                                    className="silverSubscription"
                                    sx={{color: '#5C6E9A', background: '#fff',borderBottomRightRadius: '0 !important', borderTopRightRadius: '0 !important', border: '1px solid #ffffff',width: '-webkit-fill-available', position: 'absolute', marginLeft: '-30px  !important' }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid item lg={12} md={12} sm={12} xs={12} xxs={12}>
                            <Typography
                              variant="subscriptionPeriodlabel"
                              sx={{ float: "left" }}
                            >
                              Annual
                            </Typography>

                            <Grid container sx={{ mb: 1 }}>
                              <Grid item lg={6} md={6} xs={6}>
                                <Typography
                                  variant="subscriptionTotalAmount"
                                  sx={{ float: "left" }}
                                >
                                  <p>{plan.currencySymbol}</p>
                                  {plan.annualAmt}
                                </Typography>
                                <Typography
                                  variant="subscriptionDiscountPercentage"
                                  sx={{ width: "min-content" }}
                                >
                                  {plan.discountValue}
                                </Typography>
                              </Grid>
                              <Grid item lg={6} md={6} xs={6}>
                                {plan.chipForAnnual && (
                                  <Chip
                                    label={plan.chipForAnnual}
                                    className="silverSubscription"
                                    sx={{color: '#5C6E9A', background: '#fff',borderBottomRightRadius: '0 !important', borderTopRightRadius: '0 !important', border: '1px solid #ffffff',width: '-webkit-fill-available', position: 'absolute', marginLeft: '-30px  !important' }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        )}

                        <Grid item lg={12} md={12} xs={12} textAlign={"left"}>
                          
                          {plan.title !== "Platinum" && <br />}
                          <Typography variant="subscriptionPerContractText">
                            Option-contracts limit per month -{" "}
                            {plan.noOfOptionTrades}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container sx={{ py: 2, px: 4 }}>
                        <Grid item lg={12} md={12} xs={12} textAlign={"left"}>
                          <Stack direction={"column"}>
                            <Typography variant="subscriptionInstitutionPeriodlabel">
                              {SubscriptionConstants.institutionMainTitle}
                            </Typography>
                            <Typography variant="subscriptionInstitutionPeriodSublabel">
                              <Link
                                href={SubscriptionConstants.contactUSLink}
                                underline="none"
                                target="_blank"
                              >
                                Contact us
                              </Link>{" "}
                              to know more...
                            </Typography>
                            <br />
                          </Stack>
                        </Grid>
                      </Grid>
                    )}
                  </Stack>
                  <Stack
                    direction={"column"}
                    sx={{ float: "left", width: "100%" }}
                  >
                    <Stack sx={{ mt: 1, px: 2 }}>
                      <IconList
                        featuresList={plan.features}
                        color={theme.palette.subscriptionColors[plan.title]}
                      />
                    </Stack>
                  </Stack>

                  {Object.keys(accountDetails).length > 0 &&
                    getButtonBasedOnCurrentPlan(index, plan.title, plan)}
                </CardWrapper>
              </Stack>
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyCard />
      )}
      <ConfirmationDialog
        open={open}
        handleCancel={handleCancel}
        handleOk={handleOk}
        action={action}
        page={"subscription"}
      />
      <ActivationModalPopup open={openActivationModal} handleClose={handleActivationModalClose} message={SubscriptionConstants.successActivationMessage} title={"Activated Successfully"} />
    </MainCard>
  );
};

export default SubscriptionPlan;

import React from "react";

import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";

// project imports
import LogoSection from "../LogoSection";
//import SearchSection from './SearchSection';
import ProfileSection from "./ProfileSection";
// import NotificationSection from './NotificationSection';
// import SettingSection from './SettingSection';
// import HelpSection from './HelpSection';
import AccountDropDownSection from "./AccountDropDownSection";
// assets
import { IconChevronLeft, IconChevronRight, IconMenu2 } from "@tabler/icons";
import CountriesList from "helpers/Countries";
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle, leftDrawerOpened }) => {
  const theme = useTheme();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
          className="mobileLogoSection"
        >
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme?.customization?.themeColor === "dark" ?  theme.darkMode.mainBackground : theme.palette.primary.light,
              color:  theme?.customization?.themeColor === "dark" ? '#fff' : theme.palette.primary.dark,
              "&:hover": {
                background: theme.palette.primary.main,
                color: theme.palette.primary.light,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            {leftDrawerOpened && <IconMenu2 stroke={1.5} size="1.3rem" />}
            {!leftDrawerOpened && (
              <IconMenu2 stroke={1.5} size="1.3rem" />
            )}
          </Avatar>
        </ButtonBase>
      </Box>
      <Box sx={{ ml: 2, flexGrow: 0.1 }} className="browserAccountDropDownView">
        <AccountDropDownSection />
        <CountriesList />
      </Box>
      {/* header search */}
      {/* <SearchSection /> */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* notification & profile */}
      {/* <HelpSection />
      <SettingSection />
      <NotificationSection /> */}
      <ProfileSection />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;

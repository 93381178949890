// assets
import {IconSettings } from '@tabler/icons';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const settings = {
  id: 'settings',
  title: 'Settings',
  type: 'group',
  children: [
    {
        id: 'add-link-account',
        title: 'Add/Link Account',
        type: 'item',
        url: '/add-link-account',
        icon: IconSettings,
        breadcrumbs: true
      },
  ]
};

export default settings;

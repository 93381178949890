import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Replace with your desired icon
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";

function IconList({ featuresList, color }) {
  const theme = useTheme();

  return (
    <List sx={{ marginTop: "0px", paddingTop: "0px" }}>
      {featuresList.map((item, index) => (
        <>
          <ListItem
            key={index}
            sx={{
              paddingTop: "0px",
              px: 0,
              span: {
                color:
                  theme?.customization?.themeColor === "dark" &&
                  "#fff !important",
              },
            }}
          >
            <ListItemIcon>
              <CheckCircleIcon
                sx={{ color: color, width: "18px", height: "18px" }}
              />
              {/* Replace 'CheckCircleIcon' with your desired icon component */}
            </ListItemIcon>
            <ListItemText primary={item.main} />
          </ListItem>
          {Array.isArray(item.sub) && item.sub.length > 0 && (
            <List
              sx={{
                marginTop: "0px",
                paddingTop: "0px",
                span: {
                  color:
                    theme?.customization?.themeColor === "dark" &&
                    "#fff !important",
                },
              }}
            >
              {item.sub.map((subitem, subIndex) => (
                <ListItem
                  key={subIndex}
                  sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                >
                  <ListItemIcon>
                    <CheckCircleIcon
                      sx={{ color: "#5C6E9A", width: "18px", height: "18px" }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={subitem} />
                </ListItem>
              ))}
            </List>
          )}
        </>
      ))}
    </List>
  );
}

export default IconList;

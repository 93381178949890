import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    IconButton,
    Tooltip,
    TextField,
    Typography,
    Grid,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useTheme } from "@mui/material/styles";
const ReferralUrlCopyDialog = (props) => {
    const theme = useTheme();
    const url = props.url // Replace with your actual URL
    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(url)
            .then(() => {
                setCopied(true);
            })
            .catch(err => {
                console.error('Failed to copy URL:', err);
                // Handle copy failure gracefully, e.g., using fallback mechanisms or notifications
            });
    };

    return (
        <Grid>
            <Dialog open={props.open} onClose={props.handleClose} >
                <DialogTitle>
                    <Typography variant="h3"
                        sx={{
                            color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading
                        }}>Earn credit for both by referring a friend with your link, valid upon their successful registration and account linking.</Typography>
                </DialogTitle>
               
                <DialogContent>
                <Grid style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Tooltip title={copied ? 'Copied!' : 'Click to copy URL'}>
                        <IconButton onClick={handleCopy}>
                            <ContentCopyIcon color={theme?.customization?.themeColor === "dark"? 'primary' : 'inherit'} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                    {/* <DialogContentText>URL:</DialogContentText> */}
                    <Typography variant="h4" sx={{ textDecoration:"underline",
                        color: theme?.customization?.themeColor === "dark" ? '#fff' : theme.heading,
                    }}>
                        {url}
                    </Typography>

                </DialogContent>
                <DialogActions>
                <Button sx={{ ...theme.buttons.primaryButton, width: 'max-content' }} onClick={props.handleClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default ReferralUrlCopyDialog;
import PropTypes from "prop-types";
import { forwardRef, useState } from "react";

// material-ui
import { Typography} from "@mui/material";
import { useTheme } from "@mui/material/styles";
// ==============================|| CUSTOM SELECT ||============================== //


const ReferralLink = forwardRef((props, ref) => {
  const theme = useTheme();
 

  

  const {
    amount
  } = props;
  return (
    <Typography sx={{ color: "#00BEFA", cursor:"pointer" }} onClick = {props.onClick} >
        Refer a friend! Get ${amount} each
    </Typography>
  );
});



export default ReferralLink;

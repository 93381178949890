import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useCookies } from "react-cookie";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase } from "@mui/material";

// assets
import { getAccounts } from "commonApi/index";
import SelectElement from "ui-component/Select";
import { SET_USER_ACCOUNT_LIST, SET_SELECTION_MODE } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "ui-component/Loader";
// ==============================|| NOTIFICATION ||============================== //

const AccountDropdownSection = forwardRef(({ superAdmin }, ref) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false)

  const accountSelectedPre = useSelector(
    (state) => state.customization.userAccountDetailsList.selectedAccount
  );
  const [accountDetailsList, setAccountDetailsList] = useState(null);
  const [accountDetails, setAccountDetails] = useState(null);
  const [cookies, setCookie] = useCookies(["login_session"]);

  useLayoutEffect(() => {
    let currentaccDetail = localStorage.getItem('selectedAccountDetails');

    if (currentaccDetail) {
      setAccountDetails(JSON.parse(currentaccDetail));
    }
    if (cookies && cookies.login_session && cookies.login_session.email) {
      fetchData(currentaccDetail ? JSON.parse(currentaccDetail).account_id : null);
    }
  }, [superAdmin]);

  const fetchData = async (selectedAccount) => {
    setIsLoading(true);
    let accountList = await getAccounts(
      superAdmin ? "" : cookies.login_session.email
    );
    if (accountList.length > 0) {
      const accountDropDown = [];
      accountList.forEach((element, index, array) => {
        accountDropDown.push({
          ...element,
          label: element.account_alias_name,
          id: element.account_id,
        });
      });
      //if (selectedAccount) {
      let accountDetailsArr = [];
      const filterAcc = accountDropDown.filter(
        (e) =>
          e.account_id ===
          (selectedAccount
            ? selectedAccount
            : accountSelectedPre && accountSelectedPre.account_id
              ? accountSelectedPre.account_id
              : accountDropDown[0].account_id)
      );
      console.log('>>>>>>>filterAcc[0]', filterAcc[0])
      setAccountDetails(filterAcc[0]);
      dispatch({
        type: SET_SELECTION_MODE,
        selectedMode: filterAcc[0].mode_of_management,
      });
      dispatch({
        type: SET_USER_ACCOUNT_LIST,
        userAccountDetailsList: {
          accountList: accountDropDown,
          selectedAccount: filterAcc[0],
        },
      });
      setAccountDetailsList(accountDropDown);
      setIsLoading(false);
      const checkSubscription = accountDropDown.filter((subscription) => subscription.payment_status === 'PAID');
      if (checkSubscription.length < 1) {
        navigate("/billing", { replace: true });
      }
    } else {
      dispatch({
        type: SET_USER_ACCOUNT_LIST,
        userAccountDetailsList: { accountList: [], selectedAccount: null },
      });
      setAccountDetails([]);
      setIsLoading(false);
      navigate("/billing", { replace: true });
    }
  };

  const handleAccountChange = async (event, newValue) => {
    const selectedAcount = newValue.account_id;
    let accountDetailsArr = [];
    if (accountDetailsList.length > 0) {
      const filter = accountDetailsList.filter(
        (e) => e.account_id === newValue.account_id
      );
      filter.sort((a, b) => a.account_alias_name - b.account_alias_name);
      accountDetailsArr = filter[0];
      dispatch({
        type: SET_SELECTION_MODE,
        selectedMode: accountDetailsArr.mode_of_management,
      })
      localStorage.setItem('selectedAccountDetails', JSON.stringify(accountDetailsArr));
      await fetchData(selectedAcount);
      const checkSubscription = accountDetailsList.filter((subscription) => subscription.payment_status === 'PAID');
      if (checkSubscription.length < 1) {
        navigate("/billing", { replace: true });
      }
    }
    setAccountDetails(accountDetailsArr);
  };

  const accountDropDown = (
    <>
      {isLoading && <Loader />}
      <SelectElement
        className={"primaryAccountDropdown"}
        itemList={accountDetailsList ? accountDetailsList : []}
        label={"Account"}
        handleChange={handleAccountChange}
        defaultValue={accountDetails}
        width={"100%"}
      />
    </>
  );

  return accountDropDown;
});

AccountDropdownSection.propTypes = {
  superAdmin: PropTypes.bool,
};

export default AccountDropdownSection;

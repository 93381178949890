/* Notes: 
      Expect id others can be changed 
      */

const OptionActivitiesHeaders = [
  {
    id: "date", //should not change
    numeric: false,
    disablePadding: false,
    label: "Date",
    align: "left",
  },
  {
    id: "ticker", //should not change
    numeric: false,
    disablePadding: false,
    label: "Ticker",
    align: "left",
  },
  {
    id: "underlying_symbol", //should not change
    numeric: false,
    disablePadding: false,
    label: "Underlying Symbol",
    align: "left",
  },  
  {
    id: "order_id", //should not change
    numeric: false,
    disablePadding: false,
    label: "Order ID",
    align: "left",
  },
  {
    id: "expiry", //should not change
    numeric: false,
    disablePadding: false,
    label: "Expiry",
    align: "center",
  },
  {
    id: "transaction_type", //should not change
    numeric: false,
    disablePadding: false,
    label: "Transaction type",
    align: "center",
  },
  {
    id: "status", //should not change
    numeric: false,
    disablePadding: false,
    label: "Status",
    align: "center",
  },
  {
    id: "qty", //should not change
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    align: "right",
  }
];

const PortfolioHeaders = [
  {
    id: "ticker_symbol", //should not change
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "left",
  },
  {
    id: "underlying_symbol", //should not change
    numeric: false,
    disablePadding: false,
    label: "Underlying Symbol",
    align: "center",
  },
  {
    id: "qty", //should not change
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    align: "right",
  },
  {
    id: "average_price", //should not change
    numeric: false,
    disablePadding: false,
    label: "Trade Price",
    align: "right",
  },
  {
    id: "current_market_price", //should not change
    numeric: false,
    disablePadding: false,
    label: "Current Market Price",
    align: "right",
  },
  // {
  //   id: "market_value", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Market Value",
  //   align: "right",
  // },
  // {
  //   id: "position_status", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Position Status",
  //   align: "center",
  // },
  {
    id: "put_call", //should not change
    numeric: false,
    disablePadding: false,
    label: "Put/Call",
    align: "center",
  },
];

const CommunityInsightsHeaders = [
  {
    id: "ticker", //should not change
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "left",
  },
  {
    id: "currentMarketPrice", //should not change
    numeric: false,
    disablePadding: false,
    label: "Market Price",
    align: "right",
  },
  {
    id: "averagePremium", //should not change
    numeric: false,
    disablePadding: false,
    label: "Avg. Premium",
    align: "right",
  },
  {
    id: "averagePremiumPercentage", //should not change
    numeric: false,
    disablePadding: false,
    label: "Avg Premium %",
    align: "right",
  },
  {
    id: "total_csp_contracts", //should not change
    numeric: false,
    disablePadding: false,
    label: "Total Contracts Placed[CSP]",
    align: "right",
  },
  {
    id: "noOfTimesEarlyProfitBooked", //should not change
    numeric: false,
    disablePadding: false,
    label: "No Early Profit Booking[CSP]",
    align: "right",
  },
  {
    id: "total_cc_contracts", //should not change
    numeric: false,
    disablePadding: false,
    label: "Total Contracts Placed[CC]",
    align: "right",
  },
  {
    id: "num_times_cc_profit_exit", //should not change
    numeric: false,
    disablePadding: false,
    label: "No Early Profit Booking[CC]",
    align: "right",
  },
  // {
  //   id: "noOfTimesCCExited", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "No of times StopLoss CC",
  //   align: "right",
  // },
  // {
  //   id: "noOfTimesCSPExited", //should not change
  //   numeric: false,
  //   disablePadding: false,
  //   label: "No of times StopLoss CSP",
  //   align: "right",
  // },
];

const invoiceHeaders = [
  {
    id: "invoiceNumber", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Number",
    align: "left",
  },
  {
    id: "tdaAccountAlias", //should not change
    numeric: false,
    disablePadding: false,
    label: "Account Alias Name",
    align: "left",
  },
  {
    id: "invoicePeriod", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Period",
    align: "center",
  },
  {
    id: "invoiceAmount", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Amount",
    align: "right",
  },
  {
    id: "subscriptionPlanName", //should not change
    numeric: false,
    disablePadding: false,
    label: "Plan Name",
    align: "center",
  },
  {
    id: "invoicePaidStatus", //should not change
    numeric: false,
    disablePadding: false,
    label: "invoice Status",
    align: "center",
  },
];

const billingInvoiceHeaders = [
  {
    id: "invoiceNumber", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Number",
    align: "left",
  },
  {
    id: "invoiceDate", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Date",
    align: "center",
  },
  {
    id: "invoiceAmount", //should not change
    numeric: false,
    disablePadding: false,
    label: "Invoice Amount",
    align: "center",
  },
  {
    id: "invoiceLink", //should not change
    numeric: false,
    disablePadding: false,
    label: "View Invoice",
    align: "center",
  },
];


const dashboardPortfolioCardHeadCells = [
  {
    id: "ticker_symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "left",
  //  with: "40px",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    align: "right",
    with: "40px",
  },
  {
    id: "average_price",
    numeric: false,
    disablePadding: false,
    label: "Trade Price",
    align: "right",
  },
  {
    id: "current_market_price",
    numeric: false,
    disablePadding: false,
    label: "Market Price",
    align: "right",
  },
];

const dashboardActivityCardHeadCells = [
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Date",
    align: "left",
  },
  {
    id: "ticker_symbol",
    numeric: false,
    disablePadding: false,
    label: "Symbol",
    align: "left",
  },
  {
    id: "underlying_symbol",
    numeric: false,
    disablePadding: false,
    label: "Underying Symbol",
    align: "left",
  },
  {
    id: "expiry",
    numeric: false,
    disablePadding: false,
    label: "Expiry",
    align: "center",
  },
  {
    id: "order_id",
    numeric: false,
    disablePadding: false,
    label: "Order ID",
    align: "center",
  },
  {
    id: "qty",
    numeric: false,
    disablePadding: false,
    label: "Quantity",
    align: "right",
  },
  
];

export {
  OptionActivitiesHeaders,
  PortfolioHeaders,
  CommunityInsightsHeaders,
  invoiceHeaders,
  billingInvoiceHeaders,
  dashboardPortfolioCardHeadCells, dashboardActivityCardHeadCells
};

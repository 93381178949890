import { useState, useRef, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
  ButtonBase,
  Card,
  CardContent,
} from "@mui/material";
import Switch from "ui-component/Switch";

// third-party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import UpgradePlanCard from "./UpgradePlanCard";
import SamplePerson from "assets/images/avatar_male_1.png";
import AvatarMale_1 from "assets/images/avatar_male_1.png";

import avatar1 from "assets/images/avatar_male_1.png";
import avatar2 from "assets/images/avatar_male_2.png";
import avatar3 from "assets/images/avatar_female_2.png";
import avatar4 from "assets/images/avatar_female_1.png";
import {
  SET_BORDER_RADIUS,
  SET_FONT_FAMILY,
  SET_THEME_COLOR,
} from "store/actions";
import {
  updateProfile,getReferralAmount
} from "commonApi";
// assets
import { IconLogout, IconSettings, IconInfoSquareRounded } from "@tabler/icons";
import ReferralLink from 'ui-component/referralLink';
import ReferralUrlCopyDialog from "ui-component/ReferralUrlCopyDialog";
// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const accountDetails = useSelector(
    (state) => state.customization.userAccountDetailsList.selectedAccount
  );
  const dispatch = useDispatch();
  const profileInfo = useSelector((state) => state.customization.profileInfo);

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(["login_session"]);
  const [sdm, setSdm] = useState(true);
  const [value, setValue] = useState("");
  const [notification, setNotification] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const [greeting, setGreeting] = useState("");
  const [selectedAvatar, SetSelectedAvatar] = useState(
    profileInfo && profileInfo.profile_picture
  );
  const [referralResponse, setReferralResponse] = useState(null);
  const [referralCopyDialogOpen, setReferralCopyDialogOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);
    localStorage.clear()

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (
      cookies.login_session === undefined ||
      moment() > moment(cookies.login_session.expiredAt)
    ) {
      localStorage.clear()
      navigate("/logout", { replace: true });
    }
    getReferralAmt();

    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    if (currentHour >= 5 && currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour >= 12 && currentHour < 17) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }

    prevOpen.current = open;
  }, [open]);

  const currentTime = new Date();
  const currentHour = currentTime.getHours();

  useEffect(() => {
    SetSelectedAvatar(
      Object.keys(profileInfo).length !== 0
        ? profileInfo.profile_picture
        : cookies.login_session && cookies.login_session.profile_picture
    );
  }, [profileInfo.profile_picture]);

  let initialColor;
  switch (customization.themeColor) {
    case `light`:
      initialColor = "light";
      break;
    case `dark`:
    default:
      initialColor = "dark";
      break;
  }

useEffect(() => {
  setThemeColor(cookies.login_session && cookies.login_session.profile_theme);
},[])

  // state - themeColor
  const [themeColor, setThemeColor] = useState(initialColor);
  useEffect(() => {
    let newTheme;
    switch (themeColor) {
      case "light":
        newTheme = "light";
        break;
      case "dark":
      default:
        newTheme = "dark";
        break;
    }
    dispatch({ type: SET_THEME_COLOR, themeColor: newTheme });
  }, [dispatch, themeColor]);

  const onChangeSetThemeColor = async(e) => {
    const values = {};
    values.profile_theme = e.target.checked === true ? "dark" : "light";
    const data = await updateProfile(cookies.login_session.email, values);
    const session = cookies.login_session;
    setCookie("login_session", {...session, ...data});
    setThemeColor(e.target.checked === true ? "dark" : "light");
    dispatch({ type: SET_THEME_COLOR, themeColor: cookies.login_session.profile_theme });
  };
  const getReferralAmt = async() =>{
    const data = await getReferralAmount();
    if(data){
      setReferralResponse(data);
    }else{
      setReferralResponse(null);
    }
  }

  const handelReferralClick = () => {
    setReferralCopyDialogOpen(true)
  }
  const handelReferralDialogClose = () => {
    setReferralCopyDialogOpen(false)
  }
  return (
    <>
      <Box
        sx={{
          mr: 2,
          [theme.breakpoints.down("md")]: {
            mr: 2,
          },
        }}
      >
        <Stack direction={"row"} spacing={2}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <Typography sx={{ color: "#00BEFA" }}>
              {cookies?.login_session?.display_name}
            </Typography>
            <Typography
              sx={{
                color:
                  theme?.customization?.themeColor === "dark"
                    ? "#fff"
                    : "#0c1e5b",
                fontWeight: 700,
              }}
            >
              {cookies?.login_session?.email}
            </Typography>
            <ReferralLink amount={referralResponse !== null ?referralResponse.referral_amount:0} onClick={handelReferralClick}  />
            
          </Stack>
          <ButtonBase sx={{ borderRadius: "12px" }} onClick={handleToggle}>
            <Avatar
              src={
                selectedAvatar
                  ? selectedAvatar === "avatar1"
                    ? avatar1
                    : selectedAvatar === "avatar2"
                    ? avatar2
                    : selectedAvatar === "avatar3"
                    ? avatar3
                    : selectedAvatar === "avatar4"
                    ? avatar4
                    : avatar1
                  : avatar1
              }
              sx={{
                // ...theme.typography.mediumAvatar,
                // margin: '8px 0 8px 8px !important',
                background: "#fff",
                //border: '1px solid gray',
                cursor: "pointer",
              }}
              ref={anchorRef}
              aria-controls={open ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              color="inherit"
            />
          </ButtonBase>
        </Stack>
      </Box>

      {/* <Chip
        sx={{
          // height: '48px',
          // alignItems: 'center',
          // borderRadius: '27px',
          // transition: 'all .2s ease-in-out',
          // borderColor: theme.palette.primary.light,
          // backgroundColor: theme.palette.primary.light,
          // '&[aria-controls="menu-list-grow"], &:hover': {
          //   borderColor: theme.palette.primary.main,
          //   background: `${theme.palette.primary.main}!important`,
          //   color: theme.palette.primary.light,
          //   '& svg': {
          //     stroke: theme.palette.primary.light
          //   }
          // },
          // '& .MuiChip-label': {
          //   lineHeight: 0
          // }
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              // ...theme.typography.mediumAvatar,
              // margin: '8px 0 8px 8px !important',
              cursor: 'pointer'
            }}
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
       // label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      /> */}
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2, pb: 0 }}>
                    <Stack sx={{ pb: 2 }}>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography
                          variant="h4"
                          sx={{
                            color:
                              theme?.customization?.themeColor === "dark" &&
                              "#fff",
                          }}
                        >
                          {greeting},
                        </Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400 }}
                        >
                          {cookies.login_session.first_name}{" "}
                          {cookies.login_session.last_name}
                        </Typography>
                      </Stack>
                      {/* <Typography variant="subtitle2">Project Admin</Typography> */}
                    </Stack>
                    {/* <Divider /> */}
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ p: 2, pt: 0 }}>
                      {accountDetails && <UpgradePlanCard handleClose={handleClose} />}

                      <Divider />
                      <Card
                        sx={{
                          bgcolor:
                            theme?.customization?.themeColor === "dark"
                              ? theme?.darkMode?.mainBackground
                              : theme.palette.primary.light,
                          my: 2,
                        }}
                      >
                        <CardContent sx={{ mx: 2 }}>
                          <Grid container spacing={3} direction="column">
                            <Grid item>
                              <Grid
                                item
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography
                                    variant="darkModeText"
                                    sx={{
                                      color:
                                        theme?.customization?.themeColor ===
                                          "dark" && "#fff",
                                    }}
                                  >
                                    Enable Dark Mode
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Switch
                                    key={
                                      "key_" +
                                      (themeColor === "dark" ? true : false)
                                    }
                                    defaultChecked={
                                      //themeColor === "dark" ? true : false
                                      cookies.login_session && cookies.login_session.profile_theme === "dark" ? true : false
                                    }
                                    onChange={onChangeSetThemeColor}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>

                      {accountDetails && <Divider />}
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor:
                            theme?.customization?.themeColor === "dark"
                              ? "transparent"
                              : theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        {!accountDetails && (
                          <ListItemButton
                            sx={{
                              borderRadius: `${customization.borderRadius}px`,
                            }}
                            //selected={selectedIndex === 0}
                            onClick={(event) =>
                              handleListItemClick(event, 0, "/billing#1")
                            }
                          >
                            <ListItemIcon>
                              <IconInfoSquareRounded
                                stroke={1.5}
                                size="1.3rem"
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography 
                                  variant="body2"
                                  sx={{
                                    color:
                                      theme?.customization?.themeColor ===
                                        "dark" && "#fff",
                                  }}
                                >
                                  Subscriptions
                                </Typography>
                              }
                            />
                          </ListItemButton>
                        )}
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          //selected={selectedIndex === 0}
                          onClick={(event) =>
                            handleListItemClick(event, 0, "/profile")
                          }
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  color:
                                    theme?.customization?.themeColor ===
                                      "dark" && "#fff",
                                }}
                              >
                                Profile Settings
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        {/* <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          //selected={selectedIndex === 1}
                          onClick={(event) =>
                            handleListItemClick(event, 1, "#")
                          }
                        >
                          <ListItemIcon>
                            <IconUser stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid
                                container
                                spacing={1}
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography variant="body2">
                                    Social Profile
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Chip
                                    label="02"
                                    size="small"
                                    sx={{
                                      bgcolor: theme.palette.warning.dark,
                                      color: theme.palette.background.default,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton> */}
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={(event) =>
                            handleListItemClick(event, 0, "/logout")
                          }
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="body2"
                                sx={{
                                  color:
                                    theme?.customization?.themeColor ===
                                      "dark" && "#fff",
                                }}
                              >
                                Logout
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <ReferralUrlCopyDialog open={referralCopyDialogOpen} handleClose={handelReferralDialogClose} url={`${process.env.REACT_APP_URL}/register?referral_code=${cookies?.login_session?.referral_code}`} />
    </>
  );
};

export default ProfileSection;

// assets
import { IconInfoSquareRounded, IconFileInvoice } from '@tabler/icons';

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const others = {
  id: 'pages',
  title: 'Accounts',
  type: 'group',
  children: [
    {
      id: 'billing',
      title: 'Subscriptions/Billing',
      type: 'item',
      url: '/billing',
      icon: IconInfoSquareRounded,
      breadcrumbs: true,
    },
    // {
    //   id: 'subscriptions',
    //   title: 'Subscriptions',
    //   type: 'item',
    //   url: '/subscriptions',
    //   icon: IconInfoSquareRounded,
    //   breadcrumbs: true,
    // },
    // {
    //   id: 'invoices',
    //   title: 'Invoices',
    //   type: 'item',
    //   url: '/invoices',
    //   icon: IconFileInvoice,
    //   breadcrumbs: true
    // }
  ]
};

export default others;

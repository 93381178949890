import axiosApi from '../helpers/axiosApi';
import { format } from "date-fns";
import dayjs from "dayjs";
import * as Constants from "constants/Common";

export const getAccounts = async (email) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/account/${email}`, 'get');
    resolve(response);
  });
}

export const getOptionsActivities = async (uuid, start_date, end_date) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/options-activity/account-id/${uuid}/start-date/${start_date}/end-date/${end_date}/`, 'get');
    if (response) {
      resolve(response);
    } else {
      resolve([])
    }
  });
}

export const getInvoice = async (email, superAdmin = false) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_OLD_API_URL}/Invoice/getInvoice?email=${superAdmin ? 'ADMIN' : email}`, 'get', '', '', '');
    resolve(response);
  });
}

export const getSubscriptionInvoice = async (account_id, superAdmin = false) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/billing/invoice?account_id=${superAdmin ? 'ADMIN' : account_id}`, 'get', '', '', '');
    resolve(response);
  });
}

export const getClientPortalURL = async (account_id, superAdmin = false) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/stripe/get-client-portalurl?account_id=${superAdmin ? 'ADMIN' : account_id}`, 'get', '', '', '');
    resolve(response);
  });
}

// export const getCommunityInsights = async () => {
//   return new Promise(async (resolve, reject) => {
//     let response = await axiosApi.callAPI(`${process.env.REACT_APP_OLD_API_URL}/Insights/getCommunityInsights`, 'get', '', '', '');
//     resolve(response);
//   });
// }
export const getCommunityInsights = async (startDate, endDate) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/community-insights?start_dt=${startDate}&end_dt=${endDate}`, 'get', '', '', '');
    resolve(response);
  });
}

export const getPortfolio = async (uuid) => {
  const currentDate = dayjs().format("YYYY-MM-DD")
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/portfolio/${uuid}/created-date/${currentDate}`, 'get', '', '', '');
    resolve(response);
  });
}

export const updateSubscription = async (accountUUID, formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/account/${accountUUID}/update/`, 'put', formValues, "application/json");
    resolve(response);
  });
}

export const getAccountSummary = async (accountUUID, startDate, endDate) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/account-summary/account-id/${accountUUID}/start-date/${startDate}/end-date/${endDate}/`, 'get', '', '', '');
    resolve(response);
  })
};

export const addNewAccount = async (formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/addNewAccount?code=${formValues.code}&email=${formValues.email}&brokerage=${formValues.brokerage}`, 'get', '', '', '', `${process.env.REACT_APP_NEW_SUBSCRIPTION_KEY}`);
    resolve(response);
  })
}

export const getStatsDetails = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/insights/stats/`, 'get');
    resolve(response);
  });
}

export const getProfileDetails = async (profileDetailsUUID) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile-details/profile-id/${profileDetailsUUID}`, 'get');
    resolve(response);
  });
}

export const updateProfile = async (email, formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile/${email}/`, 'put', formValues, "application/json");
    resolve(response);
  });
}

export const getProfile = async (email) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile/${email}/`, 'get');
    resolve(response);
  });
}

export const updateProfileDetails = async (profileUUID, formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile-details/profile-id/${profileUUID}/`, 'put', formValues, "application/json");
    resolve(response);
  });
}

export const createProfileDetails = async (profileDetailsUUID, formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile-details/`, 'post', formValues, "application/json");
    resolve(response);
  });
}


export const postSchwabApi = async (formValues) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_URL}/accounts/addNewAccountDev/schwab`, 'post', formValues, "application/json", process.env.REACT_APP_SCHWAB_SUBSCRIPTION_KEY);
    resolve(response);
  });
}


export const updateAccountSubcription = async (account_id) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/invoice/account/${account_id}`, 'get', {}, "application/json");
    resolve(response);
  });
}

export const runOndemandTradesWheel = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/CORE/execute-ondemand-trades/`, 'get');
    resolve(response);
  });
}

export const getInvoiceSubscription = async (accountUUID) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/invoice/account/${accountUUID}`, 'get');
    resolve(response);
  });
}

export const getReferralAmount = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/profile/referral-amount-lookup/`, 'get');
    resolve(response);
  });
}


export const getBotResponse = async (payload) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/bot/generate-response/v2/`, 'post', payload, "application/json");
    resolve(response);
  });
}
// eTrade/getToken
export const getEtradetoken = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/eTrade/getToken`, 'get');
    resolve(response);
  });
}

// https://qtcommonutilsbeta.azurewebsites.net/bot/generate/thread_id/


export const getChatBotThreadId = async () => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/bot/generate/thread_id/`, 'get', {}, "application/json");
    resolve(response);
  });
}

export const getNewAccountFinalResponse = async (email,verifier,token,secret) => {
  return new Promise(async (resolve, reject) => {
    let response = await axiosApi.callAPI(`${process.env.REACT_APP_NEW_API_URL}/eTrade/setVerifier/${email}/${verifier}/${token}/${secret}`, 'get', {}, "application/json");
    resolve(response);
  });
}
